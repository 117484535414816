import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../../components/layout'
import Hero from '../../components/heroSubpage'
import SEO from '../../components/seo'
import AltSection from '../../components/columnAlternatingStyled'
import Card from '../../components/card'

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const Lab = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <div id="sub-page">
    <Layout>
      <SEO
        title={post.frontmatter.meta.title}
        description={post.frontmatter.meta.description}
        url={data.site.siteMetadata.siteUrl + location.pathname} 
        image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.gatsbyImageData.images.fallback.src}       
        />

      <Hero
        class="intro-65"
        image={post.frontmatter.image.childImageSharp.gatsbyImageData}
        foreimage={null}
        forealt={post.frontmatter.imageAltText}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        type="root"
        gradientClass="mask d-flex justify-content-center align-items-center gradient"
      />

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div className="font-w-400 text-xs-extra-large text-opening-para"> {post.frontmatter.openingpara} </div>
              <div className="mt-3 text-medium richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} /> </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      {post.frontmatter.section && (
        <>
          {post.frontmatter.section.map((sections, index) => {
            return (
              <div key={index}>
                <ConditionalWrapper
                  condition={index >= 0}
                  wrapper={children => (
                    <section
                      className={
                        index === 0 ? 'bg-white-grey-gradient' : 'bg-white'
                      }
                    >
                      <MDBContainer>
                        <MDBRow className="pb-5">
                          <MDBCol>
                            <div className="">
                              <span id={index === 0 ? 'physical' : 'virtual'} className="anchor" >
                                &nbsp;<br />&nbsp;<br />&nbsp;
                              </span>
                              {sections.title && 
                              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3"> {sections.title} </h3> }
                              <div className="mt-1 pb-5 richtext divlink text-medium" dangerouslySetInnerHTML={{ __html: sections.description, }} /> {children}
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </section>
                  )}
                >
                  {/* Physical labs */}
                  <MDBRow className="py-5">
                    <MDBCol>
                      <div className="about-overview">
                        {post.frontmatter.section.title &&
                        <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-extra-large pb-5"> {post.frontmatter.section.title} </h3> }
                        {index === 0 ? (
                          <>
                            {sections.feature.map((features, findex) => {
                              return (
                                <AltSection
                                  key={findex}
                                  title={features.linktext}
                                  subtitle={features.title}
                                  description={features.description}
                                  image={features.image.childImageSharp.gatsbyImageData}
                                  imageAltText={features.alttext}
                                  placement={findex === 0 ? 'right' : 'left'}
                                  titleClass="font-alt font-w-400 letter-spacing-1 pb-4 title-xs-medium title-medium text-very-dark-blue"
                                  link={features.link}
                                  colour="ficon-digital"
                                />
                              )
                            })}
                          </>
                        ) : (
                          <>
                            {/* Innovation center*/}
                            <MDBRow>
                              {sections.feature.map((features, findex) => {
                                return (
                                  <Card
                                    key={findex}
                                    collg="3"
                                    colmd="6"
                                    height="2.0rem"
                                    titleClass="font-alt font-w-700 letter-spacing-1 title-extra-small title-xs-medium mt-2"
                                    title={features.title}
                                    subtitle=""
                                    description={features.description}
                                    image={features.image.childImageSharp.gatsbyImageData}
                                    imageAltText={features.alttext}
                                    link={features.link}
                                    blogLink={true}
                                  />
                                )
                              })}
                            </MDBRow>
                          </>
                        )}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </ConditionalWrapper>
              </div>
            )
          })}
        </>
      )}
    </Layout>
    </div>
  )
}
export default Lab


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      frontmatter: { template: { eq: "other" }, title: { eq: "Lab" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        openingpara
        alttext
          image {
            childImageSharp {
              gatsbyImageData(width: 1980, quality: 90) 
            }
          }
        section {
          title
          description
          feature {
            title
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
            alttext
            description
            linktext
            link
          }
        }
      }
      html
    }
  }
`